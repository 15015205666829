import type { Schemas } from "#shopware";
import { couponCartItemTypes } from "@/constants/couponCartItemTypes";

const itemTypesToNotDisplay = Object.values(couponCartItemTypes) as string[];

export const useFilteredCartItems = createSharedComposable(() => {
  const { cartItems } = useCart();

  const isGreetingCard = (item: Schemas["LineItem"]) => {
    return item.payload?.is_greeting_card;
  };

  const filteredCartItems = computed(() => {
    return (
      cartItems.value?.filter(
        (item) => !itemTypesToNotDisplay.includes(item.type),
      ) ?? []
    );
  });

  const filteredCartItemsWithoutGreetingCards = computed(() => {
    return filteredCartItems.value.filter((item) => !isGreetingCard(item));
  });

  const voucherOrCouponItems = computed(() => {
    return (
      cartItems.value?.filter((item) =>
        itemTypesToNotDisplay.includes(item.type),
      ) ?? []
    );
  });

  const areProductsCompletelyPaidByVouchers = computed(() => {
    const cartTotalReducedByVouchers = cartItems.value.reduce((acc, item) => {
      if (
        item.type !== "product" &&
        item.type !== couponCartItemTypes["EASY_COUPON"]
      )
        return acc;
      return acc + item.price.totalPrice;
    }, 0);
    return cartTotalReducedByVouchers === 0;
  });

  return {
    filteredCartItems,
    voucherOrCouponItems,
    areProductsCompletelyPaidByVouchers,
    filteredCartItemsWithoutGreetingCards,
  };
});
